import { For, Show, createEffect, createMemo, createSignal } from 'solid-js';
import type { JSX } from 'solid-js';
import { IconCheck, IconSearch, InfiniteScroll, Popover } from '~/components/ui';
import { useLocalization } from '~/hooks/useLocalization';
import { stringToColor, throttle } from '~/utils/tools';
import type { Promisable } from '~/utils/types';

export type EntrySearchProps<T extends { id: unknown }, U extends boolean | undefined> = {
  placeholder?: string;
  multiple?: U;
  selected?: U extends true ? T[] : T;
  onSelect?: (selected: U extends true ? T[] : T) => void;
  exclude?: (item: T) => boolean | undefined;
  doNotRenderSelected?: boolean;
};

export const SearchSelect = <T extends { id: unknown }, U extends boolean | undefined = undefined>(
  props: {
    fetcher: (query: string | undefined, page: number) => Promisable<{ items: T[]; totalPages: number }>;
    renderItem: (item: T) => JSX.Element;
    renderSelected: keyof T | ((item: T) => JSX.Element);
  } & EntrySearchProps<T, U>
) => {
  const { t } = useLocalization();
  const [list, setList] = createSignal<T[]>([]);
  const [pagination, setPagination] = createSignal<{ page: number; totalPages: number }>();
  const [error, setError] = createSignal<unknown>();
  const [query, setQuery] = createSignal('');
  const [selected, setSelected] = createSignal<T[]>([]);

  createEffect(() => props.selected && setSelected((Array.isArray(props.selected) ? props.selected : [props.selected]) as T[]));

  const isSelected = (item: T) => selected().some((i) => i.id === item.id);

  const ended = () => {
    const c = pagination();
    return c != null && c.totalPages <= c.page;
  };

  const renderSelected = createMemo(() => {
    if (typeof props.renderSelected === 'function') return props.renderSelected;
    const key = props.renderSelected;
    return (item: T) => item[key] as string;
  });

  const errorMessage = () => {
    const err = error();
    if (err === null) {
      return;
    }
    if (err instanceof Error) {
      return err.message;
    }
    return t('Operation failed, please try again later');
  };

  const handleQuery = throttle((q: string) => {
    setQuery(q);
    setPagination(undefined);
    setError(undefined);
    setList([]);
  }, 500);

  const handleSelect = (e: MouseEvent, item: T) => {
    if (props.multiple) {
      if (!props.doNotRenderSelected) {
        if (isSelected(item)) {
          setSelected((prev) => prev.filter((i) => i.id !== item.id));
        } else {
          setSelected((prev) => [...prev, item]);
        }
      }
      props.onSelect?.(selected() as U extends true ? T[] : T);
      e.preventDefault();
    } else {
      if (!props.doNotRenderSelected) {
        setSelected([item]);
      }
      props.onSelect?.(item as U extends true ? T[] : T);
    }
  };

  const handleRemove = (e: MouseEvent, item: T) => {
    e.stopImmediatePropagation();
    setSelected((prev) => prev.filter((i) => i.id !== item.id));
    props.onSelect?.(selected() as U extends true ? T[] : T);
  };

  const handleLoad = async () => {
    const page = (pagination()?.page || 0) + 1;
    try {
      const { items, totalPages } = await props.fetcher(query(), page);
      setPagination({ page, totalPages });
      setList((prev) => [...prev, ...items].filter((i) => !props.exclude?.(i)));
    } catch (err) {
      setError(err);
    }
  };

  const handleOpenOnly = (e: MouseEvent) => {
    const trigger = e.currentTarget as Element;
    if (trigger.ariaExpanded === 'true') {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  return (
    <Popover
      onOpenChange={(open) => open || handleQuery('')}
      onOutsideClick={(e: MouseEvent) => (e.currentTarget as Node).contains(e.relatedTarget as Node) && e.preventDefault()}>
      <Popover.Trigger
        class="focus-within:ring-primary aria-expanded:ring-primary relative flex w-full flex-wrap items-center gap-2 rounded-md border bg-inputbox-bg px-2.5 py-0.5 text-sm text-title-gray outline-none placeholder:text-auxiliary-text focus-within:ring-1 aria-expanded:ring-1"
        onClick={handleOpenOnly}>
        <Show when={selected().length > 0}>
          <div class="flex select-none flex-wrap gap-1 py-1">
            <For each={selected()}>
              {(item) => {
                const label = renderSelected()(item);
                return (
                  <button
                    type="button"
                    class="bg-current-alpha flex items-center gap-1 rounded-md px-1.5 text-sm text-[--c] after:text-base after:content-['×']"
                    style={{ '--c': stringToColor(typeof label === 'string' ? label : (item.id as string)) }}
                    onClick={(e) => handleRemove(e, item)}
                    title={t('Remove select')}>
                    {renderSelected()(item)}
                  </button>
                );
              }}
            </For>
          </div>
        </Show>
        <input
          class="flex-basis-[80px] flex-1 truncate bg-transparent px-1 py-1.5 outline-none"
          value={query()}
          onInput={(e) => handleQuery(e.currentTarget.value)}
          onKeyUp={(e) => {
            if (query() || e.key !== 'Backspace') return;
            props.onSelect?.(setSelected((prev) => prev.slice(0, -1)) as U extends true ? T[] : T);
          }}
          placeholder={!props.multiple && selected().length !== 0 ? undefined : props.placeholder ?? t('Search')}
        />
        <IconSearch class="pointer-events-none absolute bottom-2.5 right-3 size-4 text-text-level03" />
      </Popover.Trigger>
      <Popover.Content
        class="thinscroll my-1 max-h-dropdown min-w-[--reference-width] space-y-0.5 overflow-auto rounded-md border border-gray-300 bg-white p-2 py-3 text-sm text-text-level02 shadow-lg"
        as={InfiniteScroll}
        threshold={100}
        ended={error() != null || ended()}
        endMessage={errorMessage()}
        onReachEnd={handleLoad}>
        <For each={list()}>
          {(item) => (
            <Popover.Trigger
              as="li"
              class="flex cursor-pointer items-center justify-between rounded-md px-3 py-2.5 transition-colors hover:bg-light-pink aria-checked:bg-light-pink"
              onClick={(e) => handleSelect(e, item)}
              aria-checked={isSelected(item)}>
              {props.renderItem(item)}
              <Show when={isSelected(item)}>
                <IconCheck class="text-primary size-5" />
              </Show>
            </Popover.Trigger>
          )}
        </For>
      </Popover.Content>
    </Popover>
  );
};
